<div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
<div class="row">
    <div class="col-md-12 col-lg-12">
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Group</th>
                    <th scope="col">Route</th>
                    <th scope="col">Vehicle</th>
                    <th scope="col">Driver</th>
                    <th scope="col">Helper</th>
                    <th scope="col">Remarks</th>
                    <th scope="col" *DataCustomAclOnly="'Employee:Admin'"><i class="fa fa-arrow-up"></i></th>
                    <th scope="col">#</th>
                    <th scope="col" *DataCustomAclOnly="'Employee:Admin'"><i class="fa fa-arrow-down"></i></th>
                    <th scope="col">Bill<br>Amount</th>
                    <th scope="col">Bill<br>Count</th>
                    <th scope="col">Customer<br>Count</th>
                    <th *DataCustomAclOnly="'Employee:Admin'" scope="col">Change <br>Route To</th>
                    <th *DataCustomAclOnly="'Employee:Admin'" scope="col">Inverse <br>Serial</th>
                    <th *DataCustomAclOnly="'Employee:Admin'" scope="col">Sort</th>
                </tr>
            <tbody>
                <tr [class.bg-success]="u.groupName=='Group1'" [class.bg-warning]="u.groupName=='Group2'"
                    *ngFor="let u of billSum">
                    <td>{{u.groupName}}</td>
                    <td>{{u.driverName=='-1'?"None":u.driverName}}</td>
                    <td class="elip" [class.bg-danger]="colorV(u)">
                        <span *DataCustomAclOnly="'Sales:Company:ReadOnly'">{{u.vehicleName}}</span>
                        <div *DataCustomAclOnly="'Employee:Admin'">
                            <select *ngIf="u.driverName !='-1'" class="form-control form-control-sm"
                                [(ngModel)]="u.vehicleName">
                                <option value="-1">None</option>
                                <option *ngFor="let p of vehiclesAll" [value]="p.vehicleName">{{p.vehicleName}}
                                </option>
                            </select>
                        </div>
                    </td>
                    <td class="elip" [class.bg-danger]="colorD(u)">
                        <span *DataCustomAclOnly="'Sales:Company:ReadOnly'">{{u.resource1}}</span>
                        <div *DataCustomAclOnly="'Employee:Admin'">
                            <select *ngIf="u.driverName !='-1'" class="form-control form-control-sm"
                                [(ngModel)]="u.resource1">
                                <option value="-1">None</option>
                                <option *ngFor="let p of empsAll" [value]="p.employeeName">{{p.employeeName}}
                                </option>
                            </select>
                        </div>
                    </td>
                    <td class="elip" [class.bg-danger]="colorH(u)">
                        <span *DataCustomAclOnly="'Sales:Company:ReadOnly'">{{u.resource2}}</span>
                        <div *DataCustomAclOnly="'Employee:Admin'">
                            <select *ngIf="u.driverName !='-1'" class="form-control form-control-sm"
                                [(ngModel)]="u.resource2">
                                <option value="-1">None</option>
                                <option *ngFor="let p of empsAll" [value]="p.employeeName">{{p.employeeName}}
                                </option>
                            </select>
                        </div>
                    </td>
                    <td class="elip">
                        <span *DataCustomAclOnly="'Sales:Company:ReadOnly'">{{u.remarks}}</span>
                        <div *DataCustomAclOnly="'Employee:Admin'">
                            <input *ngIf="u.driverName !='-1'" type="text" [(ngModel)]="u.remarks"
                                class="form-control form-control-sm" />
                        </div>
                    </td>
                    <td *DataCustomAclOnly="'Employee:Admin'">
                        <i class="fa fa-arrow-up" *ngIf="u.driverName !='-1'" (click)="onUpDownG(true,u)"></i>
                    </td>
                    <td>
                        <div *ngIf="u.driverName !='-1'">
                            {{u.serial}}
                        </div>
                    </td>
                    <td *DataCustomAclOnly="'Employee:Admin'">
                        <i class="fa fa-arrow-down" *ngIf="u.driverName !='-1'" (click)="onUpDownG(false,u)"></i>
                    </td>
                    <td>{{u.billValue }}</td>
                    <td>{{u.billCount }}</td>
                    <td>{{u.customerCount }}</td>
                    <td class="elip" *DataCustomAclOnly="'Employee:Admin'">
                        <select class="form-control form-control-sm" [(ngModel)]="u.driverName1"
                            (change)="selectChangeHandler31($event,u)">
                            <option value="-1">None</option>
                            <option *ngFor="let p of customersAll" [value]="p.resourceName">{{p.resourceName}}
                            </option>
                        </select>
                    </td>
                    <td *DataCustomAclOnly="'Employee:Admin'">
                        <button class="btn btn-sm btn-info" (click)="inverse(u)">Inverse</button>
                    </td>
                    <td *DataCustomAclOnly="'Employee:Admin'">
                        <button class="btn btn-sm btn-info" (click)="trySort(u)">Sort</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="row" *DataCustomAclOnly="'Employee:Admin'">
    <div class="col-md-12 col-lg-12">
        <span *ngFor="let u of customersAll">
            <span *ngIf="u.contactPhone=='Group1' && u.resourceName !='-1' && u.resourceName!='Cancel bill'&& u.resourceName !='Hold bill'
                && u.resourceName !='1PendingRoute' 
                && u.resourceName !='2PendingRoute'
                && u.resourceName !='3PendingRoute'
                && u.resourceName !='4PendingRoute'
                && u.resourceName !='5PendingRoute'
                && u.resourceName !='6PendingRoute'
                ">
                <button class="btn btn-sm" (click)="onChangeSet(u.resourceName)" [ngClass]="{ 
                'btn-success': isChecked(u.resourceName) ==true,
                'btn-warning':  isChecked(u.resourceName) ==false}">
                    {{u.resourceName}}</button>&nbsp;
            </span>
        </span>
    </div>
</div><Br>
<div class="row">
    <div class="col-md-12 col-lg-12">
        <button class="btn btn-sm btn-info" (click)="showLoadSheet()">Load Sheet</button>
        <table *ngIf="isLoadSheetView" class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Company</th>
                    <th scope="col">Product</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let u of loadSheetResult">
                    <td>{{u.companyName}}</td>
                    <td>{{u.productName}}</td>
                    <td>{{u.quantity}}</td>
                    <td>{{u.prodValue6}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="row">
    <div class="col-md-3 col-lg-2">
        <label>Group</label><Br>
        <input type="radio" name="revRadio1Group" value="ALL" [(ngModel)]="selecteddriverGroup"
            (change)="selectChangeHandlerDriver1($event)" />
        ALL
        <input type="radio" name="revRadio1Group" value="Group1" [(ngModel)]="selecteddriverGroup"
            (change)="selectChangeHandlerDriver1($event)" />
        Group1
        <input type="radio" name="revRadio1Group" value="Group2" [(ngModel)]="selecteddriverGroup"
            (change)="selectChangeHandlerDriver1($event)" />
        Group2
    </div>
    <div class="col-md-3 col-lg-2">
        <label>Drivers</label>
        <select class="form-control form-control-sm" [(ngModel)]="selecteddriver"
            (change)="selectChangeHandlerDriver($event)">
            <option value="0" selected>All</option>
            <option value="-1">None</option>
            <option *ngFor="let p of customers" [value]="p.resourceName">{{p.resourceName}}</option>
        </select>
    </div>
    <div class="col-md-3 col-lg-2">
        <label>Company</label>
        <select class="form-control form-control-sm" [(ngModel)]="selectedcompany"
            (change)="selectChangeHandlerCompany($event)">
            <option value="-1" selected>All</option>
            <option *ngFor="let p of companys" [value]="p.companyName">{{p.companyName}}</option>
        </select>
    </div>
    <div class="col-md-3 col-lg-2">
        <label for="zone">Zone</label>
        <select class="form-control form-control-sm" [(ngModel)]="selectedzone"
            (change)="selectChangeHandlerZone($event)">
            <option value="-1" selected>All</option>
            <option value="Zone1">Zone1</option>
            <option value="Zone2">Zone2</option>
            <option value="Zone3">Zone3</option>
            <option value="Zone4">Zone4</option>
            <option value="Zone5">Zone5</option>
            <option value="Zone6">Zone6</option>
            <option value="Zone7">Zone7</option>
            <option value="Zone8">Zone8</option>
            <option value="Zone9">Zone9</option>
            <option value="Zone10">Zone10</option>
            <option value="Zone11">Zone11</option>
            <option value="Zone12">Zone12</option>
            <option value="Zone13">Zone13</option>
            <option value="Zone14">Zone14</option>
        </select>
    </div>
    <div class="col-md-3 col-lg-2">
        <label>Customer/Bill</label>
        <input type="text" class="form-control  form-control-sm" (keyup)="onKey1($event)"
            [(ngModel)]="selectedcustomerName" />
    </div>
    <div class="col-md-3 col-lg-2">
        <label>Stock</label><Br>
        <input type="radio" name="revRadio1" value="-1" [(ngModel)]="selectedstock"
            (change)="selectChangeHandlerStock($event)" />
        All
        <input type="radio" name="revRadio1" value="1" [(ngModel)]="selectedstock"
            (change)="selectChangeHandlerStock($event)" />
        In
        <input type="radio" name="revRadio1" value="0" [(ngModel)]="selectedstock"
            (change)="selectChangeHandlerStock($event)" />
        No
    </div>
    <div class="col-md-3 col-lg-2">
        <label>Pending</label><Br>
        <input type="radio" name="revRadio11" value="-1" [(ngModel)]="selectedPending"
            (change)="selectChangeHandlerPending($event)" />
        All
        <input type="radio" name="revRadio11" value="0" [(ngModel)]="selectedPending"
            (change)="selectChangeHandlerPending($event)" />
        0
        <input type="radio" name="revRadio11" value="1" [(ngModel)]="selectedPending"
            (change)="selectChangeHandlerPending($event)" />
        1
        <input type="radio" name="revRadio11" value="2" [(ngModel)]="selectedPending"
            (change)="selectChangeHandlerPending($event)" />
        2+
    </div>
    <div class="col-md-3 col-lg-2">
        <label>&nbsp;</label><Br>
        <div>
            <button *DataCustomAclOnly="'Admin:Employee'" class="btn btn-sm btn-info"
                (click)="saveGrn()">Save</button>&nbsp;
            <button class="btn btn-sm btn-info" (click)="download()">Download</button>
        </div>
    </div>
    <div class="col-md-3 col-lg-2" *DataCustomAclOnly="'Employee:Admin'">
        <label>&nbsp;</label><Br>
        <button class="btn btn-sm btn-info" (click)="showlist()">List</button>&nbsp;
        <button class="btn btn-sm btn-info" (click)="showmap()">map</button>
    </div>
    <div class="col-md-3 col-lg-4" *DataCustomAclOnly="'Employee:Admin'">
        <label>BillIds-CommaSeparated</label>
        <div class="input-group mb-3">
            <input type="text" class="form-control form-control-sm" [(ngModel)]="billIds">
            <div class="input-group-append">
                <button class="btn btn-sm btn-info" (click)="shortRouteMove()">ShortRoute</button>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-lg-2" *DataCustomAclOnly="'Employee:Admin'">
        <label>Change To</label>
        <select class="form-control form-control-sm" (change)="selectChangeHandler311($event)">
            <option value="-1" selected>Select</option>
            <option *ngFor="let p of customersAll" [value]="p.resourceName">{{p.resourceName}}
            </option>
        </select>
    </div>
    <div class="col-md-3 col-lg-2">
        <label>MultiOrderCustomer</label><Br>
        <input type="radio" name="revRadio110" value="-1" [(ngModel)]="selectedPending1"
            (change)="selectChangeHandlerPending1($event)" />
        All
        <input type="radio" name="revRadio110" value="1" [(ngModel)]="selectedPending1"
            (change)="selectChangeHandlerPending1($event)" />
        1+
        <input type="radio" name="revRadio110" value="2" [(ngModel)]="selectedPending1"
            (change)="selectChangeHandlerPending1($event)" />
        2+
        <input type="radio" name="revRadio110" value="3" [(ngModel)]="selectedPending1"
            (change)="selectChangeHandlerPending1($event)" />
        3+
    </div>
    <div class="col-md-3 col-lg-2">
        <label>Information3(Area)</label><Br>
        <input type="text" class="form-control form-control-sm" [(ngModel)]="information3Filter">
    </div>
    <div class="col-md-3 col-lg-2" *DataCustomAcl="'Employee:Admin'">
        <label>&nbsp;</label><Br>
        <input type="number" class="form-control form-control-sm" [(ngModel)]="increment">
        <button class="btn btn-sm btn-info" (click)="showmapTest()">mapTest</button>
    </div>
    <div class="col-md-3 col-lg-2">
        <label>BillValue ></label><Br>
        <input type="number" class="form-control form-control-sm" [(ngModel)]="billValueFilter">
    </div>
</div>
<div class="row">
    <div class="col-4" style="color: red;">
        {{dbVersion}}<br>
        Bill# : Red = No Stock<br>
        BillDate : Yellow = Customer Order<br>
        BillDate : Red = SalesmanOrder<br>
        BillValue : Yellow = EwayBill<br>
        Customer : Yellow = New<br>
        Customer : Red = No Map Link<br>
        Route/ChangeTo : Red = Customer Hold Bill<br>
        Margin : Red = Config Margin and Bill Margin not same<br>
        PO Exp Date: Red = Expired
    </div>
    <div class="col-8" *DataCustomAclOnly="'Employee:Admin'">
        <div class="row">
            <div class="col-6">
                <label>Customer</label>
                <input type="text" readonly="true" class="form-control  form-control-sm" [(ngModel)]="selCustName" />
            </div>
            <div class="col-6">
                <label>MapLink</label> &nbsp;<i *ngIf="selMap" (click)="goToLink()" class="fa fa-map-marker"></i>
                <input type="text" class="form-control  form-control-sm" [(ngModel)]="selMap" />
            </div>
            <div class="col-3">
                <label>Infor1(deliverytime)</label>
                <input type="text" class="form-control  form-control-sm" [(ngModel)]="selInfor1" />
            </div>
            <div class="col-3">
                <label>Infor3(area)</label>
                <input type="text" class="form-control  form-control-sm" [(ngModel)]="selInfor" />
            </div>
            <div class="col-6">
                <label>Address</label>
                <input type="text" class="form-control  form-control-sm" [(ngModel)]="seladdress" />
            </div>
            <div class="col-6">
                <label>&nbsp;</label><Br>
                <div>
                    <button class="btn btn-sm btn-info" *ngIf="selcustId>0" (click)="updateCust()">Update</button>
                    &nbsp;
                    <button class="btn btn-sm btn-info" *ngIf="selcustId>0&&!selhasReviw"
                        (click)="MarkReviewedCust()">Reviewed</button>
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<agm-map *ngIf="isMapView" [latitude]="lat" [longitude]="lng">
    <agm-direction [waypoints]="waypoints" *ngFor="let dir of directions" [origin]="dir.origin"
        [renderOptions]="renderOptions" [markerOptions]="markerOptions" [destination]="dir.destination">
    </agm-direction>
</agm-map>
<div class="row">
    <div class="col order-last">
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Bill#</th>
                    <th scope="col">BillDate</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Pend</th>
                    <th scope="col">Company</th>
                    <th scope="col">#</th>
                    <th scope="col">Customer</th>
                    <th scope="col">infor3</th>
                    <th scope="col">Route</th>
                    <th scope="col" *DataCustomAclOnly="'Employee:Admin'">ChangeTo</th>
                    <th scope="col">Remarks</th>
                    <th scope="col" *DataCustomAclOnly="'Employee:Admin'"><i class="fa fa-arrow-up"></i></th>
                    <th scope="col">#</th>
                    <th scope="col" *DataCustomAclOnly="'Employee:Admin'"><i class="fa fa-arrow-down"></i></th>
                    <th scope="col"><i class="fa fa-inr"></i></th>
                    <th scope="col">infor1</th>
                    <th scope="col">PO Number</th>
                    <th scope="col">PO Exp Date</th>
                    <th scope="col">PO Apt Time</th>
                    <th scope="col">P.BillDate</th>
                    <th scope="col">Margin</th>
                    <th scope="col">Zone</th>
                </tr>
            </thead>
            <tbody *ngFor="let u of getData()">
                <tr [class.active]="color(u)">
                    <!-- <td><input type="checkbox" [(ngModel)]="u.selected"></td>-->
                    <td [class.bg-danger]="!u.inStock">{{u.billNumber}}</td>
                    <td [class.bg-warning]="u.custOrder==1" [class.bg-danger]="u.saleOrder||u.custOrder==2">
                        {{u.billDate |
                        date:'dd/MM/yy'}}</td>

                    <td [class.bg-warning]="u.billValue > 50000">{{u.billValue}}</td>
                    <td>
                        <button class="btn btn-sm btn-success" (click)="showDetail(u)">{{u.pending}}</button>
                    </td>
                    <td [copy-clipboard]="u.address">{{u.companyName}}</td>
                    <td [class.bg-warning]="u.pendingPost>0">
                        {{u.pendingPost>0?u.pendingPost:''}}
                    </td>
                    <td class="elip" [class.bg-warning]="u.hasReviewed==false" [class.bg-danger]="u.mapLink==''"
                        [copy-clipboard]="u.customerName">
                        <div (click)="onClickCustomername(u)">{{u.customerIdcount}}{{u.customerName}}</div>
                    </td>
                    <td class="elip">{{u.information3}}</td>
                    <td class="elip" [class.bg-danger]="isHoldBillCust(u.customerId)">
                        <div *ngIf="u.hasReviewed">
                            <span
                                *DataCustomAclOnly="'Sales:Company:ReadOnly'">{{u.driverName=='-1'?"None":u.driverName}}
                            </span>
                            <select *DataCustomAclOnly="'Employee:Admin'" class="form-control form-control-sm"
                                (change)="selectChangeHandler3($event,u)" [(ngModel)]="u.driverName1">
                                <option value="-1">None</option>
                                <option *ngFor="let p of customers" [value]="p.resourceName">{{p.resourceName}}
                                </option>
                            </select>
                        </div>
                    </td>
                    <td class="elip" *DataCustomAclOnly="'Employee:Admin'"
                        [class.bg-danger]="isHoldBillCust(u.customerId)">
                        <div *ngIf="u.hasReviewed">
                            <select class="form-control form-control-sm" (change)="selectChangeHandler3($event,u)">
                                <option value="-1" selected>Select</option>
                                <option *ngFor="let p of customers1" [value]="p.resourceName">{{p.resourceName}}
                                </option>
                            </select>
                        </div>
                    </td>
                    <td class="elip">
                        <span *DataCustomAclOnly="'Sales:Company:ReadOnly'">{{u.billRemarks}}</span>
                        <input title="{{u.billRemarks}}" *DataCustomAclOnly="'Employee:Admin'" (blur)="onBlurEvent(u)"
                            type="text" [(ngModel)]="u.billRemarks" class="form-control form-control-sm" />
                    </td>
                    <td *DataCustomAclOnly="'Employee:Admin'">
                        <i class="fa fa-arrow-up" (click)="onUpDown(true,u)"></i>
                    </td>
                    <td [class.bg-success]="u.groupName=='Group1'" [class.bg-warning]="u.groupName=='Group2'">
                        {{u.serial}}
                    </td>
                    <td *DataCustomAclOnly="'Employee:Admin'">
                        <i class="fa fa-arrow-down" (click)="onUpDown(false,u)"></i>
                    </td>
                    <td>
                        <span *DataCustomAclOnly="'Sales:Company:ReadOnly'">{{u.cashCarry?'Y':'N'}}</span>
                        <input *DataCustomAclOnly="'Admin:Employee'" [value]="u.billId" type="checkbox"
                            [(ngModel)]="u.cashCarry" (change)="onCheckboxChange1($event)">
                    </td>
                    <td class="elip">{{u.information1}}</td>
                    <td>{{u.poNumber}}</td>
                    <td [class.bg-danger]="coloralerPOtDate(u)">{{u.poExpDate | date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.appointDate | date: 'dd/MM/yyyy hh:mm'}}</td>
                    <td [class.bg-warning]="coloralertDate(u)">
                        {{getDays(u)}};{{u.prevBillDate | date:'dd/MM/yy'}}</td>

                    <td [class.bg-danger]="coloralertM(u)">{{u.margin}}/{{u.configMargin}}</td>
                    <td [copy-clipboard]="u.address">{{u.zone}}</td>
                </tr>
                <tr *ngIf="u.showDetail">
                    <td colspan="24">
                        <div class="form-row" *ngIf="u.showDetail">
                            <div class="col-md-2">
                                Bounced Checks
                                <table class="table table-striped table-sm table-responsive table-condensed">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">Bill Number</th>
                                            <th scope="col">Check Details</th>
                                        </tr>
                                    <tbody>
                                        <tr *ngFor="let u of pendingFollowups">
                                            <td>{{u.billNumber}}</td>
                                            <td>{{u.bouncedCheckDetail }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-4">
                                Pending Bills
                                <table class="table table-striped table-sm table-responsive table-condensed">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">Age</th>
                                            <th scope="col">Company</th>
                                            <th scope="col">Bill#</th>
                                            <th scope="col">Bill Date</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Collected</th>
                                            <th scope="col">Balance</th>
                                            <th scope="col">Assigned</th>
                                        </tr>
                                    <tbody>
                                        <tr *ngFor="let u of pendingBills"
                                            [class.bg-warning]="u.companyName==this.selectedCompany">
                                            <td>{{ calculateDiff(u.billDate) }}</td>
                                            <td>{{u.companyName}}</td>
                                            <td>{{u.billNumber}}</td>
                                            <td>{{u.billDate | date: 'dd/MM/yyyy'}}</td>
                                            <td>{{u.billValue }}</td>
                                            <td>{{u.colVal }}</td>
                                            <td>{{u.balance }}</td>
                                            <td>{{u.personName }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-6">
                                Pending Cheque Clerance
                                <table class="table table-striped table-sm table-responsive table-condensed">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">Cheque#</th>
                                            <th scope="col">Cheque Date</th>
                                            <th scope="col">Collected</th>
                                            <th scope="col">Remarks</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Mode</th>
                                            <th scope="col">CollectedBy</th>
                                            <th scope="col">Bill Date</th>
                                            <th scope="col">Bill#</th>
                                            <th scope="col">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let u of colls">
                                            <td>{{u.chequeNumber}}</td>
                                            <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}</td>
                                            <td>{{u.collectionValue}}</td>
                                            <td>{{u.remarks}}</td>
                                            <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
                                            <td>{{u.mode}}</td>
                                            <td>{{u.collectedBy}}</td>
                                            <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                                            <td>{{u.billNumber}}</td>
                                            <td>{{u.billValue}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6">
                                <div>
                                    CustomerPhone : {{u.customerPhone}}<br>
                                    Saleman : {{u.salesPersonName}}<br>
                                    SalesmanPhone : {{u.salesContactPhone}}<br>
                                </div>
                            </div>
                            <div class="col-md-6">
                <tr *DataCustomAclOnly="'Employee:Admin'">
                    <td colspan="2">
                        <textarea rows="2" class="form-control form-control-sm" [(ngModel)]="addNotes.notes"></textarea>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-info" (click)="saveNoteTrack1(u)">Add Note</button>
                    </td>
                    <td><input type="date" class="form-control form-control-sm" [(ngModel)]="addNotes.customerFolDate">
                    </td>
                    <td>
                        <button class="btn btn-sm btn-info" (click)="saveNoteTrack2(u)">Add FollowUp</button>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-info" *ngIf="u.customerFolDate!=null"
                            (click)="saveNoteTrack3(u)">Remove
                            FollowUp</button>
                    </td>
                </tr>
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">User</th>
                            <th scope="col">Notes</th>
                            <th scope="col">Date</th>
                            <th scope="col">Bill#</th>
                            <th scope="col">BillValue</th>
                            <th scope="col">NoteType</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let u of suppliedDocs">
                            <td>{{u.userName}}</td>
                            <td>{{u.notes}}</td>
                            <td>{{u.enteredDate| date: 'dd/MM/yy hh:mm a'}}</td>
                            <td>{{u.billNumber}}</td>
                            <td>{{u.billValue}}</td>
                            <td>{{u.noteType}}</td>
                        </tr>
                    </tbody>
                </table>
    </div>
</div>
</td>
</tr>
</tbody>
</table>
</div>
<div class="w-100"></div>
</div>