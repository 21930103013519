import { formatDate } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentModel } from 'src/app/models';
import { TenantCollectionModel, TenantModel } from 'src/app/models1';
import { PmService } from 'src/app/_services/pm.service';
import { RayarApiService } from 'src/app/_services/rayar-api.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-rana',
  templateUrl: './rana.component.html',
  styleUrls: ['./rana.component.css']
})
export class RanaComponent implements OnInit {
  loading = false;
  error = '';
  submitted = false;
  customers: TenantCollectionModel[] = [];
  tenants: TenantModel[] = [];
  rentalHistory: TenantCollectionModel[] = [];

  selectedDate = new Date().getUTCFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2);

  constructor(private api: PmService, private formBuilder: FormBuilder, private imageCompress: NgxImageCompressService,
    private rapi: RayarApiService) {
    this.selectedDate = new Date().getUTCFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2);
    this.onSearch();
  }

  ngOnInit(): void {
    this.billAddEditFormNew = this.formBuilder.group({
      mode: ['', Validators.required],
      amount1: [''],
      amount2: [''],
    });
    this.billAddEditForm = this.formBuilder.group({
      mode: ['', Validators.required],
      amount: ['', Validators.required],
      amountType: ['', Validators.required],
    });
    this.billAddEditForm1 = this.formBuilder.group({
      name: ['', Validators.required],
      mode: ['', Validators.required],
      amount: ['', Validators.required],
      amountType: ['', Validators.required],
    });
    this.billAddEditForm2 = this.formBuilder.group({
      personName: ['', Validators.required],
      phone1: ['', Validators.required],
      phone2: [''],
      email: [''],
      remarks: [''],
      advanceAmount: ['', Validators.required],
      monthlyRent: ['', Validators.required],
      monthlyMaintenance: ['',],
      moveInDate: ['',],
      moveOutDate: ['',],
    });
  }

  selectChangeHandler(event: any) {
    this.selectedDate = event.target.value;
  }

  historypage: number = 0;
  getRentCollectHistory(id: number, p: number) {
    this.rentalHistory = [];
    this.historypage = p;
    this.api.GetRentCollectHistory(id, this.historypage)
      .subscribe(result => this.rentalHistory = result, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  downloadRanaRes() {
    this.rapi.downloadBlobFilePost("Download1/0/RanaRes", null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            var fileName = new Date().getUTCFullYear() + "RanaRes.jpg";
            if (data.body.type == "application/zip") {
              fileName = new Date().getUTCFullYear() + "RanaRes.zip"
            } else if (data.body.type == "application/pdf") {
              fileName = new Date().getUTCFullYear() + "RanaRes.pdf"
            }
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = fileName;
            a.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }

  @ViewChild('inputFile') inputFile: ElementRef;
  @ViewChild('inputFile1') inputFile1: ElementRef;

  upload(event) {
    this.error = "";
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.startsWith("image")) {
          var imageSrc = reader.result as string;
          this.imageCompress.compressFile(imageSrc, 50, 100).then((compressedImage) => {
            var doc = new jsPDF();
            doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
            this.onUploadRanaRes(doc.output('blob'));
          });
        }
        else {
          if (file.type.startsWith("application/pdf")) {
            this.onUploadRanaRes(file);
          } else {
            this.error = "Invalid File format images/PDF only";
            this.inputFile.nativeElement.value = '';
          }
        }
      };
    }
  }

  upload1(event) {
    this.error = "";
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.startsWith("image")) {
          var imageSrc = reader.result as string;
          this.imageCompress.compressFile(imageSrc, 50, 100).then((compressedImage) => {
            var doc = new jsPDF();
            doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
            this.onUploadRanaLease(doc.output('blob'));
          });
        }
        else {
          if (file.type.startsWith("application/pdf")) {
            this.onUploadRanaLease(file);
          } else {
            this.error = "Invalid File format images/PDF only";
            this.inputFile1.nativeElement.value = '';
          }
        }
      };
    }
  }

  sel: TenantModel;

  setupload(u: TenantModel) {
    this.sel = u;
  }

  onUploadRanaLease(file: Blob) {
    this.rapi.uploadToBlob(file, `UploadRanaRes/${this.sel.tenantId}/0/RanaLease`).subscribe(
      data => {
        if (data) {
          switch (data.type) {
            case HttpEventType.UploadProgress:
              break;
            case HttpEventType.Response:
              this.inputFile1.nativeElement.value = '';
              confirm("File Uploaded!!!!")
              break;
          }
        }
      },
      error => {
        this.inputFile1.nativeElement.value = '';
      }
    );
  }

  onUploadRanaRes(file: Blob) {
    var vsturl = `UploadRanaRes/${new Date().getUTCFullYear()}/${new Date().getMonth() + 1}/RanaRes`;
    this.rapi.uploadToBlob(file, vsturl).subscribe(
      data => {
        if (data) {
          switch (data.type) {
            case HttpEventType.UploadProgress:
              break;
            case HttpEventType.Response:
              this.inputFile.nativeElement.value = '';
              confirm("File Uploaded!!!!")
              break;
          }
        }
      },
      error => {
        this.inputFile.nativeElement.value = '';
      }
    );
  }

  onDownloadDocument(i: number) {
    this.rapi.downloadBlobFilePost("Download/" + i, null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = i.toString();
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }
  onpage(next: boolean) {
    var d6 = this.selEmp;
    if (d6 == "") return;
    var i = d6.split(';');
    var tid = parseInt(i[0]);
    var rid = parseInt(i[1]);

    if (next) {
      this.getRentCollectHistory(rid, this.historypage + 1);
    }
    else {
      if (this.historypage >= 1)
        this.getRentCollectHistory(rid, this.historypage - 1);
    }
  }

  modes: string[] = [];
  selectedMode: string = 'All';
  onSearch() {
    var d = this.selectedDate.split('-');
    var y = Number(d[0]);
    var m = Number(d[1]);
    this.api.GetTenantCollection(m, y).subscribe(result => {
      this.customers = result;
      this.modes = this.customers.map(item => item.mode)
        .filter((value, index, self) => self.indexOf(value) === index);
      this.GetTenants();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  MarkInActiveTentantCollection(u: TenantCollectionModel) {
    this.api.MarkInActiveTentantCollection(u.tenantCollectionId).subscribe(result => {
      this.onSearch();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  UpdateTentantState(u: TenantModel, s: number) {
    this.api.UpdateTentantState(u.tenantId, s).subscribe(result => {
      this.onSearch();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  notes: string;

  GetTenants() {
    this.api.GetTenants().subscribe(result => {
      this.tenants = result;
      this.tenants.forEach(u => {
        if (u.unitName == "Basement") this.notes = u.ruRemarks;
        if (u.tenantState != 1) return false;
        var colls = this.customers.filter(function (hero) {
          return ((hero.rentalUnitId == u.rentalUnitId && hero.tenantId == u.tenantId));
        });
        var d = 0;
        var d1 = 0;
        colls.forEach(element => {
          if (element.amountType == "Rent")
            d = d + element.amount;
          if (element.amountType == "Maintenance")
            d1 = d1 + element.amount;
        });
        u.rentDue = d<=0;
        u.maintDue = u.monthlyMaintenance != d1;
      });
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  selectedzone: string = '';
  selEmp: string = "";
  billAddEditFormNew: FormGroup;
  billAddEditForm: FormGroup;
  billAddEditForm1: FormGroup;
  billAddEditForm2: FormGroup;
  get f() { return this.billAddEditForm.controls; }
  get f1() { return this.billAddEditForm1.controls; }
  get f2() { return this.billAddEditForm2.controls; }
  get fNew() { return this.billAddEditFormNew.controls; }

  OnAttendance(s: TenantModel) {
    if (!s.tenantId) return;
    var s1 = s.tenantId + ';' + s.rentalUnitId;
    if (this.selEmp == s1) {
      this.selEmp = "";
    } else {
      this.selEmp = s1;
      this.billAddEditForm.setValue({
        mode: '',
        amount: '',
        amountType: '',
      });
      this.billAddEditForm1.setValue({
        name: '',
        mode: '',
        amount: '',
        amountType: '',
      });
      var r = s.monthlyRent;
      var m = s.monthlyMaintenance;
      this.billAddEditFormNew.setValue({
        mode: 'Cash',
        amount1: r,
        amount2: m,
      });
      this.getRentCollectHistory(s.rentalUnitId, 0);
    }

  }

  selectTenantModel: TenantModel;
  showselectTenantModel: boolean = false;
  close() {
    this.showselectTenantModel = false;
  }
  UpdateTentant(s: TenantModel) {
    this.showselectTenantModel = true;
    this.selectTenantModel = s;
    if (s.tenantId) {
      this.billAddEditForm2.patchValue({
        personName: s.personName,
        phone1: s.phone1,
        phone2: s.phone2,
        email: s.email,
        remarks: s.remarks,
        advanceAmount: s.advanceAmount,
        monthlyRent: s.monthlyRent,
        monthlyMaintenance: s.monthlyMaintenance,
        moveInDate: formatDate(s.moveInDate, 'yyyy-MM-dd', 'en'),
        moveOutDate: formatDate(s.moveOutDate, 'yyyy-MM-dd', 'en'),
      });
    } else {
      this.billAddEditForm2.patchValue({
        personName: '',
        phone1: '',
        phone2: '',
        email: '',
        remarks: '',
        advanceAmount: '',
        monthlyRent: '',
        monthlyMaintenance: '',
        moveInDate: '',
        moveOutDate: '',
      });
    }
  }

  onSubmitTenant() {
    this.submitted = true;
    if (this.billAddEditForm2.invalid) return;
    this.loading = true;
    var billModel = new TenantModel();
    billModel.advanceAmount = this.f2.advanceAmount.value;
    billModel.personName = this.f2.personName.value;
    var p1 = this.f2.phone1.value;
    if (p1 && p1 > 0)
      billModel.phone1 = p1.toString();
    var p2 = this.f2.phone2.value;
    if (p2 && p2 > 0)
      billModel.phone2 = p2.toString();
    billModel.email = this.f2.email.value;
    billModel.monthlyRent = this.f2.monthlyRent.value;
    billModel.monthlyMaintenance = this.f2.monthlyMaintenance.value;
    if (this.f2.moveInDate.value)
      billModel.moveInDate = this.f2.moveInDate.value;
    if (this.f2.moveOutDate.value)
      billModel.moveOutDate = this.f2.moveOutDate.value;
    billModel.remarks = this.f2.remarks.value;
    billModel.rentalUnitId = this.selectTenantModel.rentalUnitId;
    if (this.selectTenantModel.tenantId)
      billModel.tenantId = this.selectTenantModel.tenantId;
    else
      billModel.tenantId = 0;

    this.api.AddEditTenant(billModel).subscribe(result => {
      this.showselectTenantModel = false;
      this.error = '';
      this.submitted = false;
      this.loading = false;
      this.onSearch();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  billModel = new TenantCollectionModel();

  onSubmit(u: TenantModel) {
    this.submitted = true;
    if (this.billAddEditForm.invalid) return;
    var d = this.selectedDate.split('-');
    var y = Number(d[0]);
    var m = Number(d[1]) - 1;
    var td = new Date();
    this.loading = true;
    this.billModel.amountType = this.f.amountType.value;
    this.billModel.mode = this.f.mode.value;
    this.billModel.amount = this.f.amount.value;
    this.billModel.tenantId = u.tenantId;
    this.billModel.rentalUnitId = u.rentalUnitId;
    this.billModel.entryDate = new Date(y, m, td.getDate());
    this.billModel.inOut = true;
    this.billModel.name = '';
    if (this.billModel.amountType == "AdvanceReturn") this.billModel.amount = this.billModel.amount * -1;
    this.api.AddTenantCollection(this.billModel).subscribe(result => {
      this.error = '';
      this.submitted = false;
      this.loading = false;
      this.selEmp = "";
      this.onSearch();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  updateNotes() {
    var t = new TenantCollectionModel();
    t.name = this.notes;
    if (this.notes)
      this.api.UpdateNotes(t).subscribe(result => {
        this.error = '';
        this.submitted = false;
        this.loading = false;
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onSubmitNew(u: TenantModel) {
    this.submitted = true;
    if (this.billAddEditFormNew.invalid) return;
    var rent = Number(this.fNew.amount1.value);
    var maint = Number(this.fNew.amount2.value);
    if (rent <= 0 && maint <= 0) return;
    var d = this.selectedDate.split('-');
    var y = Number(d[0]);
    var m = Number(d[1]) - 1;
    var td = new Date();
    this.loading = true;

    if (rent > 0) {
      var billModel = new TenantCollectionModel();
      billModel.amountType = "Rent";
      billModel.mode = this.fNew.mode.value;
      billModel.amount = this.fNew.amount1.value;
      billModel.tenantId = u.tenantId;
      billModel.rentalUnitId = u.rentalUnitId;
      billModel.entryDate = new Date(y, m, td.getDate());
      billModel.inOut = true;
      billModel.name = '';
      this.api.AddTenantCollection(billModel).subscribe(result => {
        this.error = '';
        this.submitted = false;
        this.loading = false;
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
    }

    if (maint > 0) {
      var billModel1 = new TenantCollectionModel();
      billModel1.amountType = "Maintenance";
      billModel1.mode = this.fNew.mode.value;
      billModel1.amount = maint;
      billModel1.tenantId = u.tenantId;
      billModel1.rentalUnitId = u.rentalUnitId;
      billModel1.entryDate = new Date(y, m, td.getDate());
      billModel1.inOut = true;
      billModel1.name = '';
      this.api.AddTenantCollection(billModel1).subscribe(result => {
        this.error = '';
        this.submitted = false;
        this.loading = false;
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
    }
    this.selEmp = "";
    this.onSearch();
  }

  onSubmitOut() {
    this.submitted = true;
    if (this.billAddEditForm1.invalid) return;
    var d = this.selectedDate.split('-');
    var y = Number(d[0]);
    var m = Number(d[1]) - 1;
    var td = new Date();
    this.loading = true;
    var billModel = new TenantCollectionModel();
    billModel.tenantCollectionId = 0;
    billModel.amountType = this.f1.amountType.value;
    billModel.mode = this.f1.mode.value;
    billModel.amount = this.f1.amount.value;
    billModel.name = this.f1.name.value;
    billModel.entryDate = new Date(y, m, td.getDate());
    billModel.inOut = false;
    if ("PrevMonthFwd" == billModel.amountType) {

    } else {
      billModel.amount = billModel.amount * -1;
    }
    this.api.AddTenantCollection(billModel).subscribe(result => {
      this.error = '';
      this.submitted = false;
      this.loading = false;
      this.selEmp = "";
      this.billAddEditForm1.setValue({
        name: '',
        mode: '',
        amount: '',
        amountType: '',
      });
      this.onSearch();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  filteroptions: string = "ALL";

  selectChangeHandler3(event: any) {
    var accountId = event.target.value;
    this.billAddEditForm1.patchValue({
      name: accountId,
    });
  }

  rentDue() {
    var c = 0;
    this.tenants.forEach(u => {
      if (u.tenantState == 1) {
        var colls = this.customers.filter(function (hero) {
          return ((hero.rentalUnitId == u.rentalUnitId && hero.tenantId == u.tenantId));
        });
        var d = 0;
        colls.forEach(element => {
          if (element.amountType == "Rent")
            d = d + element.amount;
        });
        if (u.monthlyRent != d) {
          c = c + 1;
        }
      }
    });
    return c;
  }


  maintDue() {
    var c = 0;
    this.tenants.forEach(u => {
      if (u.tenantState == 1) {
        var colls = this.customers.filter(function (hero) {
          return ((hero.rentalUnitId == u.rentalUnitId && hero.tenantId == u.tenantId));
        });
        var d = 0;
        colls.forEach(element => {
          if (element.amountType == "Maintenance")
            d = d + element.amount;
        });
        if (u.monthlyMaintenance != d) {
          c = c + 1;
        }
      }
    });
    return c;
  }


  vacantCount() {
    var c = 0;
    this.tenants.forEach(u => {
      if (!u.tenantId) c = c + 1;
    });
    return c;
  }

  getCustomers() {
    var d1 = this.selectedMode;
    return this.customers.filter(function (hero) {
      return (d1 == "All" || hero.mode == d1)
    });
  }

  getData1() {
    var d6 = this.selEmp;
    if (d6 == "") return [];
    var i = d6.split(';');
    var tid = parseInt(i[0]);
    var rid = parseInt(i[1]);
    return this.customers.filter(function (hero) {
      return ((hero.rentalUnitId == rid && hero.tenantId == tid))
    });
  }

  sortMovein: boolean = false;
  sortMoveout: boolean = false;
  sortMoveout1: boolean = false;

  onMoveout1() {
    this.sortMoveout1 = !this.sortMoveout1;
    this.sortMoveout = false;
    this.sortMovein = false;
  }

  onMoveIn() {
    this.sortMovein = !this.sortMovein;
    this.sortMoveout = false;
    this.sortMoveout1 = false;
  }

  onMoveout() {
    this.sortMoveout = !this.sortMoveout;
    this.sortMovein = false;
    this.sortMoveout1 = false;
  }

  getData() {
    var d6 = this.selectedzone;
    var d1 = this.filteroptions;
    var ret = this.tenants.filter(function (hero) {
      return ((d6 == ''
        || (hero.category && hero.category.toLowerCase().includes(d6.toLowerCase()))
        || (hero.personName && hero.personName.toLowerCase().includes(d6.toLowerCase()))
        || hero.phone1 && (hero.phone1.toLowerCase().includes(d6.toLowerCase()))
        || hero.ruRemarks && (hero.ruRemarks.toLowerCase().includes(d6.toLowerCase()))
        || (hero.phone2 && hero.phone2.toLowerCase().includes(d6.toLowerCase()))
        || (hero.unitName && hero.unitName.toLowerCase().includes(d6.toLowerCase())))
        && (d1 == "ALL" || (d1 == "Vacant" && !hero.tenantId) || (d1 == "Rent" && hero.rentDue) || (d1 == "Maint" && hero.maintDue)));
    });

    if (this.sortMoveout) {
      return ret.sort((a, b) => a.moveOutMonths - b.moveOutMonths);
    }
    if (this.sortMovein) {
      return ret.sort((a, b) => a.moveInMonths - b.moveInMonths);
    }
    if (this.sortMoveout1) {
      return ret.sort((a, b) => b.noOfRents - a.noOfRents);
    }
    return ret.sort((a, b) => a.category.localeCompare(b.category) || a.unitName.localeCompare(b.unitName));
  }

}
