<input name="file" id="file" (change)="upload($event)" type="file" #inputFile hidden>
<input name="file" id="file1" (change)="upload1($event)" type="file" #inputFile1 hidden>
<div class="row">
    <div class="col">
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <div class="form-row">
            <div class="col-md-2">
                <label for="From"> Month</label>
                <input type="month" [(ngModel)]="selectedDate" class="form-control" />
            </div>
            <div class="col-md-2">
                <label></label><br>
                <button class="btn btn-sm btn-info" (click)="onSearch()">Search</button>&nbsp;
            </div>
            <div class="col-md-2">
                <label></label><br>
                <button class="btn btn-sm btn-info" (click)="inputFile.click()">Upload</button>&nbsp;
                <button class="btn btn-sm btn-info" (click)="downloadRanaRes()">Download</button>&nbsp;
            </div>
        </div>
        <br>
        <div class="form-row">
            <div class="col-md-3 col-lg-2">
                <label>Mode</label>
                <select class="form-control form-control-sm" [(ngModel)]="selectedMode">
                    <option value="All" selected>All</option>
                    <option *ngFor="let p of modes" [value]="p">{{p}}</option>
                </select>
            </div>
        </div>
        <br>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-light">
                <tr>
                    <th></th>
                    <th>Running Balance</th>
                    <th>Amount</th>
                    <th>Amount Type</th>
                    <th>Mode</th>
                    <th>Entry Date</th>
                    <th>Description</th>
                    <th>Monthly Rent</th>
                    <th>Monthly Maint.</th>
                    <th>Person Name</th>
                    <th>Unit Name</th>
                    <th>Category</th>
                    <th>Phone1</th>
                    <th>Phone2</th>
                    <th>Tenant State</th>
                    <th>Remarks</th>
                    <th>Adv Amount</th>
                </tr>
            </thead>
            <tbody *ngFor="let u of getCustomers()">
                <tr>
                    <td>
                        <button class="btn btn-sm btn-warning"
                            (click)="MarkInActiveTentantCollection(u)">Delete</button>
                    </td>
                    <td>{{u.runningBalance}}</td>
                    <td>{{u.amount}}</td>
                    <td>{{u.amountType}}</td>
                    <td>{{u.mode}}</td>
                    <td>{{u.entryDate | date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.name}}</td>
                    <td>{{u.monthlyRent}}</td>
                    <td>{{u.monthlyMaintenance}}</td>
                    <td>{{u.personName}}</td>
                    <td>{{u.unitName}}</td>
                    <td>{{u.category}}</td>
                    <td>{{u.phone1}}</td>
                    <td>{{u.phone2}}</td>
                    <td>{{u.inOut?u.tenantState=="1"?"Current": u.tenantState=="2"?"Future":"Old":""}}</td>
                    <td>{{u.remarks}}</td>
                    <td>{{u.advanceAmount}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<hr color="red">
<div class="row">
    <div class="col">
        <form [formGroup]="billAddEditForm1" (ngSubmit)="onSubmitOut()">
            <div class="form-row">
                <div class="col-md-2">
                    <label for="amountType">Amount Type</label>
                    <select formControlName="amountType" class="form-control" (change)="selectChangeHandler3($event)"
                        [ngClass]="{ 'is-invalid': submitted && f1.amountType.errors }">
                        <option value="" selected disabled>Choose</option>
                        <option value="LaxmanSalary">LaxmanSalary</option>
                        <option value="PowerBill">PowerBill</option>
                        <option value="Association">Association</option>
                        <option value="Paint">Paint</option>
                        <option value="Cleaning">Cleaning</option>
                        <option value="Lift">Lift</option>
                        <option value="AgreementCharge">AgreementCharge</option>
                        <option value="Other1">Other1</option>
                        <option value="Other2">Other2</option>
                        <option value="Other3">Other3</option>
                        <option value="CollectedBy">CollectedBy</option>
                        <option value="PrevMonthFwd">PrevMonthFwd</option>
                    </select>
                    <div *ngIf="submitted && f1.amountType.errors" class="invalid-feedback">
                        <div *ngIf="f1.amountType.errors.required">Amount Type is required
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label for="name">Description</label>
                    <input type="text" formControlName="name" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f1.name.errors }" />
                    <div *ngIf="submitted && f1.name.errors" class="invalid-feedback">
                        <div *ngIf="f1.name.errors.required">Description is required</div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label for="mode">Payment Mode</label>
                    <select formControlName="mode" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f1.mode.errors }">
                        <option value="" disabled>Choose</option>
                        <option value="Cash" selected>Cash</option>
                        <option value="TriAcc">TriAcc</option>
                        <option value="MaheAcc">MaheAcc</option>
                        <option value="GowAcc">GowAcc</option>
                        <option value="Acc1">Acc1</option>
                        <option value="Acc2">Acc2</option>
                        <option value="Acc3">Acc3</option>
                    </select>
                    <div *ngIf="submitted && f1.mode.errors" class="invalid-feedback">
                        <div *ngIf="f1.mode.errors.required">Payment Mode is required
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label for="amount">Amount</label>
                    <input type="number" formControlName="amount" class="form-control" min="1"
                        [ngClass]="{ 'is-invalid': submitted && f1.amount.errors }" />
                    <div *ngIf="submitted && f1.amount.errors" class="invalid-feedback">
                        <div *ngIf="f1.amount.errors.required">Amount is required</div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label>&nbsp;</label><br>
                    <button type="submit" [disabled]="loading" class="btn btn-outline-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Save
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
<div *ngIf="showselectTenantModel">
    <hr color="red">
    <div class="row">
        <div class="col">
            <form [formGroup]="billAddEditForm2" (ngSubmit)="onSubmitTenant()">
                <div class="form-row">
                    <div class="col-md-2">
                        <label for="personName">PersonName</label>
                        <input type="text" formControlName="personName" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f2.personName.errors }" />
                        <div *ngIf="submitted && f2.personName.errors" class="invalid-feedback">
                            <div *ngIf="f2.personName.errors.required">PersonName is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="phone1">Phone1</label>
                        <input type="number" formControlName="phone1" class="form-control" maxlength="10"
                            [ngClass]="{ 'is-invalid': submitted && f2.phone1.errors }" />
                        <div *ngIf="submitted && f2.phone1.errors" class="invalid-feedback">
                            <div *ngIf="f2.phone1.errors.required">Phone1 is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="phone2">Phone2</label>
                        <input type="number" formControlName="phone2" class="form-control" maxlength="10"
                            [ngClass]="{ 'is-invalid': submitted && f2.phone2.errors }" />
                        <div *ngIf="submitted && f2.phone2.errors" class="invalid-feedback">
                            <div *ngIf="f2.phone2.errors.required">Phone2 is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="email">Email</label>
                        <input type="text" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f2.email.errors }" />
                        <div *ngIf="submitted && f2.email.errors" class="invalid-feedback">
                            <div *ngIf="f2.email.errors.required">Email is required</div>
                        </div>
                    </div>
                    <div class="w-100"></div>
                    <div class="col-md-2">
                        <label for="advanceAmount">Advance Amount</label>
                        <input type="number" formControlName="advanceAmount" class="form-control" min="0"
                            [ngClass]="{ 'is-invalid': submitted && f2.advanceAmount.errors }" />
                        <div *ngIf="submitted && f2.advanceAmount.errors" class="invalid-feedback">
                            <div *ngIf="f2.advanceAmount.errors.required">Advance Amount is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="monthlyRent">Monthly Rent</label>
                        <input type="number" formControlName="monthlyRent" class="form-control" min="0"
                            [ngClass]="{ 'is-invalid': submitted && f2.monthlyRent.errors }" />
                        <div *ngIf="submitted && f2.monthlyRent.errors" class="invalid-feedback">
                            <div *ngIf="f2.monthlyRent.errors.required">Monthly Rent is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="monthlyMaintenance">Monthly Maintenance</label>
                        <input type="number" formControlName="monthlyMaintenance" class="form-control" min="0"
                            [ngClass]="{ 'is-invalid': submitted && f2.monthlyMaintenance.errors }" />
                        <div *ngIf="submitted && f2.monthlyMaintenance.errors" class="invalid-feedback">
                            <div *ngIf="f2.monthlyMaintenance.errors.required">Monthly Maintenance is required</div>
                        </div>
                    </div>
                    <div class="w-100"></div>
                    <div class="col-md-2">
                        <label for="moveInDate">MoveIn Date</label>
                        <input type="date" formControlName="moveInDate" class="form-control" min="0"
                            [ngClass]="{ 'is-invalid': submitted && f2.moveInDate.errors }" />
                        <div *ngIf="submitted && f2.moveInDate.errors" class="invalid-feedback">
                            <div *ngIf="f2.moveInDate.errors.required">MoveIn Date is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="moveOutDate">Lease Date</label>
                        <input type="date" formControlName="moveOutDate" class="form-control" min="0"
                            [ngClass]="{ 'is-invalid': submitted && f2.moveOutDate.errors }" />
                        <div *ngIf="submitted && f2.moveOutDate.errors" class="invalid-feedback">
                            <div *ngIf="f2.moveOutDate.errors.required">Lease Date is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="remarks">Remarks</label>
                        <input type="text" formControlName="remarks" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f2.remarks.errors }" />
                        <div *ngIf="submitted && f2.remarks.errors" class="invalid-feedback">
                            <div *ngIf="f2.remarks.errors.required">Remarks is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label>&nbsp;</label><br>
                        <button type="submit" [disabled]="loading" class="btn btn-outline-primary">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Add/Update
                        </button>
                    </div>
                    <div class="col-md-2">
                        <label>&nbsp;</label><br>
                        <button class="btn btn-outline-primary" (click)="close()">
                            Close
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<hr color="red">
<div class="row">
    <div class="col">
        <div class="form-row">
            <div class="col-md-2">
                <label for="From"> Search</label>
                <input type="text" [(ngModel)]="selectedzone" class="form-control" />
            </div>
            <div class="col-md-4">
                <input type="radio" name="revRadio" value="ALL" [(ngModel)]="filteroptions" /> ALL
                <input type="radio" name="revRadio" value="Rent" [(ngModel)]="filteroptions" /> Rent {{rentDue()}}
                <input type="radio" name="revRadio" value="Maint" [(ngModel)]="filteroptions" /> Maint {{maintDue()}}
                <input type="radio" name="revRadio" value="Vacant" [(ngModel)]="filteroptions" /> Vacant
                {{vacantCount()}}
            </div>
            <div class="col-md-4">
                <textarea  rows="3" (blur)="updateNotes()" type="text" [(ngModel)]="notes" class="form-control form-control" ></textarea>
            </div>
        </div>
        <br>
        <div class="form-row">
            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-light">
                    <tr>
                        <th>remarks</th>
                        <th>Unit Name</th>
                        <th>Person Name</th>
                        <th>Phone</th>
                        <th (click)="onMoveout1()">NoRent</th>
                        <th>Monthly Rent</th>
                        <th>Monthly Maint.</th>
                        <th>Total</th>
                        <th>Adv. Amount/Collected</th>
                        <th>Tenant State</th>
                        <th (click)="onMoveIn()">MoveIn Date</th>
                        <th (click)="onMoveout()">Lease Date</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody *ngFor="let u of getData()">
                    <tr (click)="OnAttendance(u)">
                        <td>{{u.ruRemarks}}</td>
                        <td [class.bg-warning]="u.rentDue" [copy-clipboard]="u.phone2">{{u.unitName}}</td>
                        <td [copy-clipboard]="u.phone1">{{u.personName}}</td>
                        <td>
                            <a *ngIf="u.phone1" [href]="'https://wa.me/+91' + u.phone1">{{u.phone1}}</a> &nbsp;
                            &nbsp;
                            <a *ngIf="u.phone2" [href]="'https://wa.me/+91' + u.phone2">{{u.phone2}}</a>
                        </td>
                        <td>{{u.noOfRents}}</td>
                        <td>{{u.monthlyRent}}</td>
                        <td [class.bg-warning]="u.maintDue">{{u.monthlyMaintenance}}</td>
                        <td>{{u.monthlyRent+u.monthlyMaintenance}}</td>
                        <td>{{u.advanceAmount}}/{{u.colAdv}}</td>
                        <td>{{!u.tenantState?"":u.tenantState=="1"?"Current": u.tenantState=="2"?"Future":"Old"}}</td>
                        <td>{{u.moveInDate | date: 'dd/MM/yyyy'}} : {{u.moveInMonths}}</td>
                        <td [class.bg-danger]="u.moveOutMonths*-1>10">
                            {{u.moveOutDate | date: 'dd/MM/yyyy'}} :
                            {{u.moveOutMonths}}
                        </td>
                        <td>
                            <button class="btn btn-sm btn-info" *ngIf="u.tenantId && u.tenantState==1"
                                    (click)="UpdateTentantState(u,3)">
                                Mark Old
                            </button>
                            <button class="btn btn-sm btn-info" *ngIf="u.tenantId && u.tenantState==2"
                                    (click)="UpdateTentantState(u,1)">
                                Mark Current
                            </button>
                        </td>
                        <td>
                            <button class="btn btn-sm btn-info" (click)="UpdateTentant(u)">AddEdit</button>
                            <button class="btn btn-sm btn-info"
                                    (click)="setupload(u);inputFile1.click()">
                                Upload
                            </button>
                            <button class="btn btn-sm btn-info" *ngIf="u.documentId>0"
                                    (click)="onDownloadDocument(u.documentId)">
                                Download
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="selEmp == u.tenantId+';'+u.rentalUnitId">
                        <td colspan="100">
                            <div class="row">
                                <div class="col">
                                    <table class="table table-striped table-sm table-responsive table-condensed">
                                        <thead class="thead-light">
                                            <tr>
                                                <th>EntryDate</th>
                                                <th>Mode</th>
                                                <th>Amount Type</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let u of getData1()">
                                            <tr>
                                                <td>{{u.entryDate | date: 'dd/MM/yyyy'}}</td>
                                                <td>{{u.mode}}</td>
                                                <td>{{u.amountType}}</td>
                                                <td>{{u.amount}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col">
                                    <table class="table table-striped table-sm table-responsive table-condensed">
                                        <thead class="thead-light">
                                            <tr>
                                                <th>EntryDate</th>
                                                <th>Mode</th>
                                                <th>Amount Type</th>
                                                <th>Amount</th>
                                                <th>
                                                    Person <a (click)="onpage(false)">&lt;</a>
                                                    <span>Page: {{historypage+1}}</span>
                                                    <a (click)="onpage(true)">&gt;</a>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let u of rentalHistory">
                                            <tr>
                                                <td>{{u.entryDate | date: 'dd/MM/yyyy'}}</td>
                                                <td>{{u.mode}}</td>
                                                <td>{{u.amountType}}</td>
                                                <td>{{u.amount}}</td>
                                                <td>
                                                    {{u.personName}}/{{u.monthlyRent}}/{{u.monthlyMaintenance}}/{{u.advanceAmount}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <form [formGroup]="billAddEditFormNew" (ngSubmit)="onSubmitNew(u)">
                                        <div class="form-row">
                                            <div class="col-md-2">
                                                <label for="mode">Collection Mode</label>
                                                <select formControlName="mode" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && fNew.mode.errors }">
                                                    <option value="" selected disabled>Choose</option>
                                                    <option value="Cash">Cash</option>
                                                    <option value="TriAcc">TriAcc</option>
                                                    <option value="MaheAcc">MaheAcc</option>
                                                    <option value="GowAcc">GowAcc</option>
                                                    <option value="Acc1">Acc1</option>
                                                    <option value="Acc2">Acc2</option>
                                                    <option value="Acc3">Acc3</option>
                                                </select>
                                                <div *ngIf="submitted && fNew.mode.errors" class="invalid-feedback">
                                                    <div *ngIf="fNew.mode.errors.required">
                                                        Collection Mode is required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <label for="amount1">Rent</label>
                                                <input type="number" formControlName="amount1" class="form-control"
                                                    min="1"
                                                    [ngClass]="{ 'is-invalid': submitted && fNew.amount1.errors }" />
                                                <div *ngIf="submitted && fNew.amount1.errors" class="invalid-feedback">
                                                    <div *ngIf="fNew.amount1.errors.required">Rent is required</div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <label for="amount2">Maintenance</label>
                                                <input type="number" formControlName="amount2" class="form-control"
                                                    min="0"
                                                    [ngClass]="{ 'is-invalid': submitted && fNew.amount2.errors }" />
                                                <div *ngIf="submitted && fNew.amount2.errors" class="invalid-feedback">
                                                    <div *ngIf="fNew.amount2.errors.required">Maintenance is required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <label>&nbsp;</label><br>
                                                <button type="submit" [disabled]="loading"
                                                    class="btn btn-outline-primary">
                                                    <span *ngIf="loading"
                                                        class="spinner-border spinner-border-sm mr-1"></span>
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <form [formGroup]="billAddEditForm" (ngSubmit)="onSubmit(u)">
                                        <div class="form-row">
                                            <div class="col-md-2">
                                                <label for="amountType">Amount Type</label>
                                                <select formControlName="amountType" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f.amountType.errors }">
                                                    <option value="" selected disabled>Choose</option>
                                                    <option value="Advance">Advance</option>
                                                    <option value="AdvanceReturn">AdvanceReturn</option>
                                                    <option value="MvOutCleanChrg">MvOutCleanChrg</option>
                                                    <option value="Other1">Other1</option>
                                                    <option value="Other2">Other2</option>
                                                    <option value="Other3">Other3</option>
                                                </select>
                                                <div *ngIf="submitted && f.amountType.errors" class="invalid-feedback">
                                                    <div *ngIf="f.amountType.errors.required">
                                                        Amount Type is required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <label for="mode">Collection Mode</label>
                                                <select formControlName="mode" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f.mode.errors }">
                                                    <option value="" selected disabled>Choose</option>
                                                    <option value="Cash">Cash</option>
                                                    <option value="TriAcc">TriAcc</option>
                                                    <option value="MaheAcc">MaheAcc</option>
                                                    <option value="GowAcc">GowAcc</option>
                                                    <option value="Acc1">Acc1</option>
                                                    <option value="Acc2">Acc2</option>
                                                    <option value="Acc3">Acc3</option>
                                                </select>
                                                <div *ngIf="submitted && f.mode.errors" class="invalid-feedback">
                                                    <div *ngIf="f.mode.errors.required">
                                                        Collection Mode is required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <label for="amount">Amount</label>
                                                <input type="number" formControlName="amount" class="form-control"
                                                    min="1"
                                                    [ngClass]="{ 'is-invalid': submitted && f.amount.errors }" />
                                                <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                                                    <div *ngIf="f.amount.errors.required">Amount is required</div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <label>&nbsp;</label><br>
                                                <button type="submit" [disabled]="loading"
                                                    class="btn btn-outline-primary">
                                                    <span *ngIf="loading"
                                                        class="spinner-border spinner-border-sm mr-1"></span>
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
