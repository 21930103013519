
export class chitModel {
  chitId: number;
  months: number;
  chitStatus: number;
  accountName: string;
  remarks: string;
  amount: number;
  fee: number;
  startDate?: Date;
  showDetail: boolean;
  auctionDate?: Date;
  chitMonth: number;
  profit: number;
}

export class chitDetailModel {
  chitId: number;
  slNo: number;
  chitDetailId: number;
  remarks: string;
  auction: number;
  paid: boolean;
  paidDate?: Date;
  auctionDate: Date;
}

export class activityModel {
  activityId: number;
  activityName: string;
  remarks: string;
  appUserID: number;
  inUse?: boolean;

}

export class accountDetailModel {
  page: number;
  accountDetailId: number;
  amount: number;
  runningBalance: number;
  remarks: string;
  isActive: boolean;
  createdBy: string;
  createdDate: Date;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  showDetail: boolean;
  entryDate: Date;
  category: string;
  inout: string;
}

export class accountDetailHistoryModel {
  accountDetailHistoryId: number;
  accountDetailId: number;
  remarks: string;
  createdBy: string;
  createdDate: Date;
  lastUpdatedBy: string;
  lastUpdatedDate: Date
}


export class TenantCollectionModel {
  runningBalance: number;
  tenantCollectionId: number;
  entryDate: Date;
  rentalUnitId?: number;
  tenantId?: number;
  mode: string;
  amount: number;
  inOut: boolean;
  name: string;
  amountType: string;

  unitName: string;
  category: string;
  personName: string;
  phone1: string;
  phone2: string;
  email: string;
  tenantState: number;
  remarks: string;
  advanceAmount?: number;
  monthlyRent?: number;
  monthlyMaintenance?: number;
  colAdv?: number;
}


export class TenantModel {
  noOfRents: number;
  documentId:number;
  ruRemarks: string;
  rentalUnitId: number;
  tenantId: number;
  unitName: string;
  category: string;
  personName: string;
  phone1: string;
  phone2: string;
  email: string;
  tenantState: number;
  remarks: string;
  advanceAmount?: number;
  monthlyRent?: number;
  monthlyMaintenance?: number;
  moveInDate?: Date;
  moveOutDate?: Date;
  colAdv?: number;
  rentDue: boolean;
  maintDue: boolean;
  advanceDue: boolean;
  vacantDue: boolean;
  moveInMonths:number;
  moveOutMonths:number;
}

export class hrChitModel {

}
